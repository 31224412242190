import React from "react"
import PropTypes from "prop-types"
import Layout from "../components/layout"

// Utilities
import kebabCase from "lodash/kebabCase"

// Components
// import { Helmet } from "react-helmet"
import { Link, graphql } from "gatsby"

const TagsPage = ({
   data: {
      allMdx: { group },
      mdx: {
         frontmatter: { title },
      },
   },
   }) => (
   <Layout>
      <article className="sheet">
         <div className="sheet__inner">
            {/* <Helmet title={title} defer={false} /> */}
            <h1 className="sheet__title">Tags</h1>
            <div className="sheet__body">
               <ul>
               {group.map(tag => (
                  <li key={tag.fieldValue}>
                     <Link to={`/tags/${kebabCase(tag.fieldValue)}/`}>
                     {tag.fieldValue} ({tag.totalCount})
                     </Link>
                  </li>
               ))}
               </ul>
            </div>
         </div>
      </article>
   </Layout>
)

TagsPage.propTypes = {
   data: PropTypes.shape({
      allMdx: PropTypes.shape({
         group: PropTypes.arrayOf(
         PropTypes.shape({
            fieldValue: PropTypes.string.isRequired,
            totalCount: PropTypes.number.isRequired,
         }).isRequired
         ),
      }),
      mdx: PropTypes.shape({
         frontmatter: PropTypes.shape({
         title: PropTypes.string.isRequired,
         }),
      }),
      // site: PropTypes.shape({
      //    siteMetadata: PropTypes.shape({
      //    title: PropTypes.string.isRequired,
      //    }),
      // }),
   }),
}

export default TagsPage

export const pageQuery = graphql`
   query {
      mdx(fields: { name: { eq: "sidebar" } }) {
         frontmatter {
            title
         }
      }
      allMdx(limit: 2000, filter: {frontmatter: {type: {eq: "project"}, published: {eq: true}}}) {
         group(field: frontmatter___tags) {
         fieldValue
         totalCount
         }
      }
   }
`